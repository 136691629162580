<template>
  <VDialog
    :value="value"
    max-width="680px"
    data-test-label="error-report-popup"
    @input="$emit('input', $event)"
  >
    <VCard>
      <VCardTitle
        class="pr-2 pl-4"
        data-test-label="error-report-title"
      >
        {{ $t('data.error_reporting.title') }}
        <VSpacer />
        <VBtn
          icon
          data-test-label="close"
          @click="close"
        >
          <VIcon>
            fal fa-times
          </VIcon>
        </VBtn>
      </VCardTitle>

      <VCardText
        class="pl-4 pb-1 pr-11"
        data-test-value="error-report-text"
      >
        {{ text }}
      </VCardText>

      <VCardActions class="pb-4">
        <TTBtn
          class="tt-text-button-2 pa-4"
          :loading="loading"
          data-test-label="download"
          data-test="tt-btn"
          @click="downloadHandle"
        >
          {{ $t('data.error_reporting.btn') }}
        </TTBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'ErrorReportingPopup',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    type: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    text() {
      if (this.type === 'processing') return this.$t('data.error_reporting.body_processing');
      return this.$t('data.error_reporting.body_upload');
    },
  },
  methods: {
    downloadHandle() {
      this.$emit('download');
    },
    close() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    overflow-y: visible !important;
    .v-card {
      .v-card__title {
        font-size: 18px;
        word-break: break-word;
      }
    }
  }

  .v-card__actions > .v-btn.v-btn {
    padding: inherit;
  }
}
</style>
